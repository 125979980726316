interface UseEsitterCallWSMessages {
  sendDeviceDebugState: (enabled: boolean) => void;
}

export const useEsitterCallWSMessages = (): UseEsitterCallWSMessages => {
  return {
    sendDeviceDebugState: () => {
      return;
    }
  };
};
